import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../head-container';
import { Range, getTrackBackground } from 'react-range';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import moment from 'moment';
import { DataLayerEventContext } from '../../contexts/dataLayerEventContext';

const RangeItem = ({ rangeValue, setRangeValue, labelText }) => {
  return (
    <>
      <label className={styles.rangeTitle}>{labelText}</label>
      <Range
        step={1}
        min={1}
        max={5}
        values={rangeValue}
        ariaLabel="Дизайн пачки"
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              display: 'flex',
              position: 'relative',
              height: '22px',
              width: '100%',
              background: getTrackBackground({
                values: rangeValue,
                colors: ['#2E0073', '#955DD6'],
                min: 1,
                max: 5
              }),
              borderRadius: '5px'
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className={styles.thumb} />}
        onChange={(values) => setRangeValue(values)}
      />
      <div className={styles.rangeItemList}>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
      </div>
    </>
  );
};

const ReviewScreen = ({
  setScreenNumber,
  setSubmitReviewScreenNumber,
  SPREADSHEET_ID = '1SmUp_vxXDmHOJN4Ie7G_5BWsfQwZY80taNNaNQdVqLE',
  SHEET_ID = '0',
  CLIENT_EMAIL = 'admin-572@lm-double-splash.iam.gserviceaccount.com',
  PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCb34seswvi3rFS\n3U+2UbS4srHv6z6YKiqzCV2Mpf4DemIbV68apT3Gsc/QvSauLVhpPT22TPzwFetJ\neP6KpKQlJDv/+8y33kZ488CNQcMKtnty1kx+xwwb320Gp3YYW1gwN927BRXPYFW+\nckUipyMZIJbQ4Aew0lFbX68RbNqg5w0rl89mSV3zHwLJsalfPnPk6VHihNEAlg1w\n+WTTkPy/6S4jcUrLnhq4H7QaqpHsGRoJaCiC4AjhyUe1Z8bUTsikLDEz/YJjVZW/\n0zlZZ1R0WbfGCKWbEjkcqam6mB1eBiunv+dRYtqoV8ojpeUy2mhV39W6bKJ6V8Rt\n6KsO/ANZAgMBAAECggEAHuXWDvTQ81zPa6yhA3P4ze/mI7fPBZy+MBU4LQwuaQQW\ndHMSK8b7kupmVmtYQoiMkPfpxrxGG1VM+Zh5ZIQ0YYRoPFrufrgZ4hn3Xkjd377C\ng8YFDdBpDxFf7ES/vR5GQfChDFR6uUvQU1sVZ0QYSf/SxX7TJ3LZW7KA4oRgZip3\njirLW2GkNaAqxya8WaiR3/gk1Q6aZeAvT8fozx9HKCQ7tWKLwALMFugUC92v+phh\noC7KnikqFOGajxoHltYvqoxEOypXbmV5Txvm9tnp2imJOECKWq6kYO29zn4EWNLX\nvIvAe1qhb9wJ7OQfCNzxeqRxTFssFf75x/cC4e578QKBgQDOX3x/9iQV/cOnvPiB\nqmYj2fFjP5yVP1faQG+2zUAynyxpvpa5PJVHP2woOYSNwPb+nB4kKUepFvGlkXNM\nWyQi2Yrg19HaKNbvmVD5UPiiPTzk7GQPHxdCA0F4NwcW3lpGXjjW90JGdToIg8hu\nygbk5OWxL7unBpJ2Kauz0z3XlQKBgQDBWz5qfan4JOFUFlRjgZdkCKSPacpt1oOP\nq5jch7QA5vOrv9TFuqcVIzawwBtbwYCStzKrW92dui1ijh56VocoOv6qVDKzOsXA\n/4a40MkDrZ3UufXh9CW0zBQX+G0f0vHwc+zYNS0nyZ7z6lpID7di5S9Fak4g4+V4\nYRyIY3Z7tQKBgCfLF7mg37Jx3TFR6eUu00UkeDw3/tx0e181P5VTHIbmVG0LeAq9\n2NcFLrjO5nVi+P19Yv58CzpalzviwQW/KUejBPl4BBn59//5Yzi/2UGHZ1+JCX40\n84oiEJS6n55UP7oD3xfsU5Y8cnVD2/ko8Y1fab7xwlBhnwoz3Mzu9aTFAoGBAKos\nMteod4IUe1f1OxpEgMgiryolEmrvA+TbQnK84Fh26vg6ofnQF6ML1eNjf/CWciEu\nKOMVvAtIQw9dQ0eqhdOLcHzaE7EYRdlhSuG3p2KcV69UZyoRJWpkvj6GfwwmLJbS\ngB2dV3uQAFxt4j1Pz7ezO5JdSBu8V0ZGOcVzvGCNAoGAVmC9bRUUU/ZRmkz0HRXM\nBUkAnvE0+m3QzLrB/4uyP6kMhXXSrsk0iqmNrwLLrvvAquZgBKcvk4YDC8UlG8NA\nKojajvngVsn4yAYiyyqQCnOZDgmNVRxkbzvJalBRTjGHUeyHwABg1EN3YoOg8GUp\nR1Tm061NiIIvt/6GR+r21HI=\n-----END PRIVATE KEY-----\n'
}) => {
  const { sendEvent } = useContext(DataLayerEventContext);
  const [designRangeValue, setDesignRangeValue] = useState([5]);
  const [filterRangeValue, setFilterRangeValue] = useState([5]);
  const [tasteQualitiesRangeValue, setTasteQualitiesRangeValue] = useState([5]);
  const [comment, setComment] = useState('');
  const [clientId, setClientId] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    const raw = match ? decodeURIComponent(match[1]) : null;

    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    const gacid = match ? match[1] : null;

    if (gacid) {
      setClientId(gacid);
    }
  }, []);

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY
      });
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      await sheet.addRow(row);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const onSubmitReview = async () => {
    try {
      sendEvent('review_send');
      setSubmitReviewScreenNumber();
      await appendSpreadsheet({
        user_id: clientId,
        filter: filterRangeValue[0],
        comment,
        flavor_properties: tasteQualitiesRangeValue[0],
        design: designRangeValue[0],
        date_time: moment(new Date()).format('LLL')
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.reviewContainer}>
        <HeadContainer setScreenNumber={setScreenNumber} />
        <p className={styles.title}>Залишити відгук про продукт</p>
        <RangeItem
          labelText="Дизайн пачки"
          rangeValue={designRangeValue}
          setRangeValue={setDesignRangeValue}
        />
        <RangeItem
          labelText="Смакові властивості"
          rangeValue={tasteQualitiesRangeValue}
          setRangeValue={setTasteQualitiesRangeValue}
        />
        <div className={styles.commentContainer}>
          <label>Ваш коментар:</label>
          <textarea
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            placeholder="Залиште свій коментар"
          />
        </div>
        <button
          className={styles.menuButton}
          style={{ marginTop: 30 }}
          onClick={() => {
            setIsSubmitDisabled(true);
            onSubmitReview();
          }}
          disabled={isSubmitDisabled}
        >
          Надіслати відгук
        </button>
        <button
          className={styles.reviewButton}
          style={{ marginTop: 14 }}
          onClick={() => setScreenNumber(1)}
        >
          Повернутися до меню
        </button>
      </div>
    </div>
  );
};

export default ReviewScreen;
