import React, { useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import AuthorityScreen from '../common/authority-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import ReviewScreen from '../components/lm-d-s/review-screen';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { useEffect } from 'react';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import AdviceChallenge from '../common/challenge-component/advice-challenge';
import { fiveSohoFactsChallengeList } from '../consts/soho-facts-challenge';
import InstantQuizChallenge from '../common/challenge-component/instant-quiz-challenge';
import FinalScreen from '../components/lm-d-s/final-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import characteristicStyles from '../common/characteristic-screen/index.module.scss';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import MuralChallenge from '../common/challenge-component/mural-challenge';

const PROJECT_NAME = 'Parliament soho vibe';

const filterCharacteristicList = [
  {
    description: (
      <>
        <span className={characteristicStyles.filterInfoBlock}>
          Ацетатна частина
        </span>
        <span className={characteristicStyles.filterInfoBlock}>
          Вугільна частина
        </span>
        <span className={characteristicStyles.filterInfoBlock}>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      </>
    ),
    bordered: false
  }
];

const characteristicInfoList = [
  {
    title: 'Формат ',
    description: '- King Size Slim (Кінг сайз слім)'
  },
  {
    title: 'Кількість сигарет у пачці ',
    description: '– 20'
  },
  {
    title: 'Кількість пачок у блоці ',
    description: '– 10'
  }
];

const menu = [
  {
    id: 'challenge',
    screenNumber: 13,
    name: 'Челендж вересня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: -1,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/'
};

const PRL_V = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init(PROJECT_NAME);
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(14);
            }}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("/images/parliament/bg.png")`}
          />
        );
      }
      case 2: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            partners={partners}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            title="PARLIAMENT SOHO VIBE"
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={filterCharacteristicList}
            infoList={characteristicInfoList}
            tobaccoFirst="Вміст смол, мг - 4"
            tobaccoSecond="Вміст нікотину, мг - 0.3"
            packImage="/images/parliament/prl-v.png"
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage="/images/parliament/bg.png"
            filterIcon="/images/parliament/filter.png"
          />
        );
      }
      case 5: {
        return (
          <CommerceVideoScreen
            videoSrc="/videos/soho-facts.mp4"
            setScreenNumber={cb}
            nextScreenNumber={6}
            changeScreenImmediately
            skipScreenImmediately
            controls={false}
            buttonStyle="skip-video"
            hasButtonArrow={true}
          />
        );
      }
      case 6: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("/images/parliament/bg.png")`}
            title={
              <>
                5 фактів<br></br>про Сохо
              </>
            }
            description="Від унікальної архітектури до творчих оаз — район Сохо в Нью-Йорку манить туристів своєю атмосферою. Ми підготували 5 фактів про Сохо, щоб познайомити тебе ближче з яскравим районом."
            buttonTitle="Почати"
            setNextScreenNumber={() => setScreenNumber(7)}
          />
        );
      }
      case 7: {
        return (
          <AdviceChallenge
            setScreenNumber={cb}
            challengeList={fiveSohoFactsChallengeList}
            backgroundImage={`url("/images/parliament/bg.png")`}
            setScreenNumberAfterResult={() => setScreenNumber(9)}
            withCounter={false}
          />
        );
      }
      case 9: {
        return (
          <InstantQuizChallenge
            setScreenNumber={setScreenNumber}
            type="soho-facts"
            backgroundImage={`url("/images/parliament/bg.png")`}
            setScreenNumberAfterResult={() => setScreenNumber(10)}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
            SHEET_ID="1008531646"
          />
        );
      }
      case 13: {
        return (
          // <QuizChallenge
          //   setScreenNumber={() => setScreenNumber(11)}
          //   type="musical-soho-playlist-to-suit-your-mood"
          //   backgroundImage={`url("/images/parliament/bg.png")`}
          //   resultTitleFontSize={48}
          //   hasListMarkers={false}
          //   hasResultPrev={true}
          //   hasResultNav={false}
          //   style={'sohoPlaylist'}
          // />
          <MuralChallenge
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("/images/parliament/bg.png")`}
          />
        );
      }
      case 14: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default PRL_V;
