import React, { useContext, useEffect, useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import ReviewScreen from '../components/lm-d-s/review-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import ProhibitedChapterScreen from '../common/prohibited-chapter-screen';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FourOptionsChallenge from '../common/challenge-component/four-options-challenge';
import AuthorityScreen from '../common/authority-screen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import QuizChallenge from '../common/challenge-component/quiz-challenge';

const filterCharacteristicList = [
  {
    description: <span>Ацетатна частина</span>,
    bordered: true
  },
  {
    description: (
      <span>
        Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким і
        більш збалансованим.
      </span>
    ),
    bordered: true
  }
];

const characteristicListTitle = {
  title: (
    <div>
      <p>
        Прогресивний Recessed<sup>тм</sup> фільтр
      </p>
      <p>
        Фільтр складається з двох <br /> частин:
      </p>
    </div>
  )
};

const characteristicInfoList = [
  {
    title: 'Формат ',
    description: '- King Size Slim (Кінг сайз слім)'
  },
  {
    title: 'Кількість сигарет у пачці ',
    description: '– 20'
  },
  {
    title: 'Кількість пачок у блоці ',
    description: '– 10'
  }
];

const characteristicStyle = {
  height: '280%'
};

const menu = [
  {
    id: 'challenge',
    screenNumber: 14,
    name: 'Челендж вересня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 6,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: -1,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  /* {
    id: 'authority',
    screenNumber: 2,
    name: 'Сигарети з капсулами забороняють?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  }, */
  {
    id: 'difference',
    screenNumber: 15,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const LM6 = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('L&M Loft Blue');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          />
        );
      }
      case 2: {
        return (
          <ProhibitedChapterScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(12)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            title="L&M Loft Sea Blue"
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={filterCharacteristicList}
            infoList={characteristicInfoList}
            characteristicListTitle={characteristicListTitle}
            tobaccoFirst="Вміст смол, мг - 4"
            tobaccoSecond="Вміст нікотину, мг - 0.3"
            packImage="images/lm-double-splash/Lm_Sea_Blue.png"
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage="/images/lm-double-splash/background-new.png"
            filterIcon="images/lm-double-splash/group_390.png"
            style={characteristicStyle}
          />
        );
      }
      case 5: {
        return (
          <FourOptionsChallenge
            setScreenNumber={setScreenNumber}
            type={'default'}
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          />
        );
      }
      case 6: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 7: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(10)}
            title="Сподіваємося"
            subtitle="тобі було цікаво"
            description="Дослухайся до наших порад і зроби зустріч із друзями незабутньою!"
            image="/images/lm-double-splash/challenge/september-challenge/finally-icon.png"
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
            SHEET_ID="1581078586"
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <QuizChallenge
            setScreenNumber={() => setScreenNumber(10)}
            type="who-are-you-in-the-company-of-friends"
            backgroundImage={`url("/images/lm-double-splash/background-new.png")`}
            resultTitleFontSize={48}
            style={'lmStyles'}
            hasResultPrev={false}
          />
        );
      }
      case 15: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default LM6;
