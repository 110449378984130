import React, { useState, useEffect } from 'react';
import styles from '../components/pmn/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../common/characteristic-screen';
import InfoScreen from '../components/lm-d-s/info-screen';
import AuthorityScreen from '../common/authority-screen';
import ReviewScreen from '../components/lm-d-s/review-screen';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import DailyTasksChallenge from '../common/challenge-component/daily-tasks-challenge';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';

const PROJECT_NAME = 'Novel Blue';

const dailyTasksChallengeList = {
  1: <>Спробуй новий вид спорту 🏓</>,
  2: <>Прочитай книгу з жанру, якого ти ніколи не читав(-ла) 📕</>,
  3: <>Напиши листа друзям чи родині та відправ його поштою ✉</>,
  4: <>Спробуй створити своїми руками подарунок для когось 🎁</>,
  5: <>Відвідай новий ресторан або кав'ярню 🍔</>,
  6: <>Проведи день без технологій 💡</>,
  7: <>Вивчи слова пісні на іншій мові та спробуй виконати її 🎵</>,
  8: <>Спробуй медитацію або йогу 🧘‍♂️</>,
  9: <>Подивися фільм у жанрі, якого ти раніше не переглядав(-ла) 🎬</>,
  10: (
    <>
      Влаштуй день самопілота: роби те, що приходить в голову, без планування 🧐
    </>
  ),
  11: <>Запиши свої мрії і цілі на папері 📜</>,
  12: <>Спробуй новий вид танцю 💃</>,
  13: <>Займись благодійністю: пожертвуй речі чи час 💸</>,
  14: <>Створи список вдячності за те, що ти маєш 📜</>,
  15: <>Вивчи рецепт та приготуй десерт 🧁</>,
  16: <>Створи свій плейлист для різних настроїв 🎼</>,
  17: <>Стань волонтером на один день ✨</>,
  18: <>Організуй пікнік у парку чи на природі 🥪</>,
  19: <>Вивчи базові фрази на іншій мові 😎</>,
  20: (
    <>Створи своє власне правило життя та дотримуйся його протягом тижня 😎</>
  ),
  21: <>Напиши вірш про своє життя 📜</>
};

const filterCharacteristicList = [
  {
    description: (
      <span>
        {' '}
        <b>Firm filter</b> - щільний ацетатний фільтр, який краще тримає форму
        та менше розмокає
      </span>
    ),
    bordered: false
  }
];

const characteristicInfoList = [
  {
    title: 'Формат ',
    description: '- King Size Slim (Кінг сайз слім)'
  },
  {
    title: 'Кількість сигарет у пачці ',
    description: '– 20'
  },
  {
    title: 'Кількість пачок у блоці ',
    description: '– 10'
  }
];

const menuList = [
  {
    id: 'challenge',
    screenNumber: 6,
    name: 'Челендж вересня',
    icon: 'images/common/menu-icon/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: -1,
    name: 'Характеристики сигарет',
    icon: 'images/common/menu-icon/characteristic-menu-icon.svg'
  },
  {
    id: 'about',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/common/menu-icon/info-menu-icon.svg'
  },
  {
    id: 'difference',
    screenNumber: 4,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review_main',
    screenNumber: 5,
    name: 'Залишити відгук про продукт',
    icon: 'images/common/menu-icon/review-menu-icon.svg'
  }
];

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/philip-morris/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=philip-morris/'
};

export default function PMNB() {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init(PROJECT_NAME);
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(14);
            }}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menuList}
            backgroundImage={`url("/images/pmn/background.png")`}
          />
        );
      }
      case 2: {
        return (
          <CharacteristicScreen
            title="PHILIP MORRIS NOVEL BLUE"
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={filterCharacteristicList}
            infoList={characteristicInfoList}
            tobaccoFirst="Вміст смол, мг - 6"
            tobaccoSecond="Вміст нікотину, мг - 0.5"
            packImage="images/pmn/pmnb/pack.png"
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(3)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage="/images/pmn/background.png"
            filterIcon="/images/pmn/filter.png"
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
          />
        );
      }
      case 4: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(9)}
            partners={partners}
          />
        );
      }
      case 5: {
        return (
          <ReviewScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(10)}
            SHEET_ID="1445004548"
          />
        );
      }
      case 6: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title={
              <>
                Готовий
                <br />
                до цікавого челенджу?
              </>
            }
            description={`Виконай наше завдання та зроби свій день яскравішим.`}
            buttonTitle="Почати"
            backgroundImage={`url("/images/pmn/background.png")`}
            setNextScreenNumber={() => setScreenNumber(7)}
          />
        );
      }
      case 7: {
        return (
          <DailyTasksChallenge
            title={
              <>
                Твій челендж <br /> на сьогодні
              </>
            }
            acceptChallengeCopy="Приймаю виклик"
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("/images/pmn/background.png")`}
            dailyTasksList={dailyTasksChallengeList}
            dailyTasksListCount={Object.keys(dailyTasksChallengeList).length}
          />
        );
      }
      case 8: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(9)}
            title="Як тобі завдання?"
            description={
              <>
                Виконай його й чудово проведи час! <br />
                <br /> Заходь до нас завтра та отримай новий челендж на день.
              </>
            }
            image="/images/challenge/challenge-result.png"
            backgroundImage={`url("/images/pmn/background.png")`}
          />
        );
      }
      case 9: {
        return (
          <FinalScreen
            setScreenNumber={cb}
            setScreenNumberToReview={() => setScreenNumber(5)}
            withReviewButton
            isUpdatedApp
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      // case 11: {
      //   return (
      //     <WelcomeChallengeScreen
      //       setScreenNumber={cb}
      //       title={<>Який ти сезон?</>}
      //       description={`Хочеш дізнатися, якій порі року найбільше відповідає твій характер? Пройди коротенький тест і дивися результати.`}
      //       buttonTitle="Почати"
      //       backgroundImage={`url("/images/pmn/background.png")`}
      //       setNextScreenNumber={() => setScreenNumber(12)}
      //     />
      //   );
      // }
      // case 12: {
      //   return (
      //     <SelectSeasonChallenge
      //       backgroundImage={`url("/images/pmn/background.png")`}
      //       setScreenNumber={setScreenNumber}
      //       setNextScreenNumber={() => setScreenNumber(13)}
      //     />
      //   );
      // }
      case 13: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(9)}
            title="Погоджуєшся з результатом?"
            description={
              <>
                Кожна людина неповторна й важливо ділитися своєю унікальністю з
                іншими.
              </>
            }
            image="/images/challenge/challenge-result-boy.png"
            backgroundImage={`url("/images/pmn/background.png")`}
          />
        );
      }
      case 14: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(9)}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };
  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
}
